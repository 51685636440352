<template>
  <div class="bg-secondary">
    <div class="container">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col text-center">
            <div class="mb-3">
              <img
                class="img-fluid img-logo-footer"
                src="../assets/images/logo-footer.svg"
              />
            </div>
            <div class="text-footer mb-3">Investing Made Simple</div>
            <hr class="hr mb-5" />
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-auto col-md-1 text-center mb-5">
            <a
              href="https://www.instagram.com/growthapp.invest/"
              target="_blank"
            >
              <img
                class="img-fluid"
                src="../assets/images/Logo/instargram.svg"
              />
            </a>
          </div>
          <div class="col-auto col-md-1 text-center mb-5">
            <a
              href="https://www.facebook.com/Growth-108982075016336"
              target="_blank"
            >
              <img class="img-fluid" src="../assets/images/Logo/facebook.svg" />
            </a>
          </div>
          <!-- <div class="col-auto col-md-1 text-center mb-5">
            <img class="img-fluid" src="../assets/images/Logo/twitter.svg" />
          </div> -->
          <div class="col-auto col-md-1 text-center mb-5">
            <a href="https://faqsupport.gitbook.io/growth/" target="_blank">
              <img class="img-fluid" src="../assets/images/Logo/book.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
