<template>
  <div class="bg-header">
    <div class="container">
      <b-navbar toggleable="lg" style="height: 70px">
        <router-link to="/"><img class="img-fluid" src="../assets/images/growth-logo.svg"
        /></router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="justify-content-end">
          <b-navbar-nav>
            <div class="text-header"><a href="https://faqsupport.gitbook.io/growth/" target="_blank">FAQ</a></div>
            <div class="text-header"><a href="https://faqsupport.gitbook.io/growth/" target="_blank">Legal</a></div>
            <!-- <b-button class="btn-signup my-2 my-sm-0 btn-lg btn-block"
              >Sign Up</b-button
            > -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>
<style scoped>
a:link {
  color: #232323;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #232323;
  background-color: transparent;
  text-decoration: none;
}
</style>