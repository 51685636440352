import axios from 'axios';
import {auth} from "@/utils/firebase";
import {signInWithPhoneNumber} from "firebase/auth";
import * as queryString from "query-string";

export async function register(data) {
    try {
        return axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/register`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: queryString.stringify(data),
            validateStatus: () => true
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (!error.response) {
                    return false;
                } else {
                    return error.response.data
                }
            });
    } catch (error) {
        throw new Error(`request error: ${error.status}`);
    }
}

export async function getOTP(phoneNumber) {
    try {
        const appVerifier = window.recaptchaVerifier;

        return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                return confirmationResult;
            }).catch((error) => {
                return error
            });
    } catch (error) {
        return error
    }
}


export async function verifyOTP(code) {
    try {
        return confirmationResult.confirm(code).then((result) => {
            return result;
        }).catch((error) => {
            return error
        });
    } catch (error) {
        return error
    }
}
