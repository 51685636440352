// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDQ5YF4xCqsj0SiTS5gZyzVwB6j2cJk-sQ",
    authDomain: "growth-s2.firebaseapp.com",
    projectId: "growth-s2",
    storageBucket: "growth-s2.appspot.com",
    messagingSenderId: "383828688376",
    appId: "1:383828688376:web:dcf160fda9fd0f0932ea0d",
    measurementId: "G-X697HY97VJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore();
