import {GET_OTP, REGISTER, VERIFY_OTP, SET_CURRENT_USER} from "@/store/actions.type";
import {getOTP, register, verifyOTP} from "@/services/auth";
import axios from "axios";

function initState() {
    return {
        isLogged: false,
        user: {},
    }
}

const state = initState();

const actions = {
    async [GET_OTP]({}, phoneNumber) {
        let response = await getOTP(phoneNumber);
        if (response) {
            return response;
        }
    },
    async [VERIFY_OTP]({}, code) {
        let response = await verifyOTP(code);
        if (response) {
            return response;
        }
    },
    async [REGISTER]({}, data) {
        return await register(data);
    },
    async [SET_CURRENT_USER]({commit}, data) {
        if (data) {
            let user = JSON.parse(JSON.stringify(data, null, '  '));
            commit(SET_CURRENT_USER, user);
            return user;
        } else {
            return false;
        }
    },
};

const mutations = {
    [SET_CURRENT_USER](state, user) {
        console.log(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.stsTokenManager.accessToken}`;

        state.user = user;
        state.isLogged = true;
    },
};

const getters = {};

export default {
    state,
    actions,
    mutations,
    getters
};
