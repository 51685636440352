import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue} from 'bootstrap-vue'
import './assets/css/main.scss'
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from "@/utils/firebase";

import './plugins/number'

Vue.config.productionTip = false
Vue.use(BootstrapVue)

import {
    SET_CURRENT_USER
} from "@/store/actions.type";

const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (user) {
        await store.dispatch(SET_CURRENT_USER, user);
    }

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');

    unsubscribe();
});



