import Vue from 'vue'

Vue.filter('numberFormat', (val, minDigit = 0, maxDigit = 8) => {
    let result = Number(val).toLocaleString(undefined, {
        minimumFractionDigits: minDigit,
        maximumFractionDigits: maxDigit
    });

    if (isNaN(val)) {
        return 0;
    }
    return result;
});

Vue.filter('formatNumberWithMark', (val, minDigit = 0, maxDigit = 2) => {
    if (!Number(val) && val !== 0) {
        return val;
    }

    let result = Number(val).toLocaleString(undefined, {
        minimumFractionDigits: minDigit,
        maximumFractionDigits: maxDigit
    });
    return result;
});
